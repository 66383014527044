import React from 'react'
import Layout from "../components/Layout/layout"
import AboutMe from "../components/AboutMe/aboutme"


const SobreMimPage = () => {
    return (
       <Layout>
            <title>Quem é Gabriela Nogueira | Dados e Tecnologia </title>
            <AboutMe>
            </AboutMe>
       </Layout>
    )
}

export default SobreMimPage